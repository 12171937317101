<template>
  <v-app id="inspire">
    <router-view />
  </v-app>
</template>


<script>
window.LOG_LEVEL = process.env.VUE_APP_LOGGING;

import "@aws-amplify/ui-vue/styles.css";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";

Amplify.configure(awsconfig);
export default {
  props: {
    source: String,
  },
  data: () => ({}),
  mounted() {

    // Hubspot Integration for Chat
    //const script = document.createElement("script");
    //script.src = "https://js.hs-scripts.com/21224081.js";
    //script.id = "hs-script-loader";
    //document.body.appendChild(script);
  },
};
</script>

<style>
.theme--light {
  
}
:root {
  --amplify-primary-color: #fb9b6c;
  --amplify-primary-tint: #ff7359;
  --amplify-primary-shade: #e0573e;
}
amplify-authenticator {
  --container-align: top;
}

#hubspot-messages-iframe-container {
  margin-bottom: 2em;
}

.v-step__content {
  text-align: left;
}
</style>