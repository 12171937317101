import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout/index2.vue";
import AmplifyStore from "@/store";
import * as AmplifyModules from "aws-amplify";
import NotFound from "../views/NotFound.vue";

const logger = new AmplifyModules.Logger("router");

async function getUser() {
  return AmplifyModules.Auth.currentAuthenticatedUser()
    .then((data) => {
      if (data && data.signInUserSession) {
        AmplifyStore.commit("SET_USER", data);
        return data;
      }
    })
    .catch((e) => {
      logger.info(e);
      AmplifyStore.commit("SET_USER", null);
      return null;
    });
}

const routes = [
  {
    path: "/ebayconnect",
    component: () => import("@/views/ebayconnect"),
    hidden: true,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login"),
    hidden: true,
  },
  {
    path: "/welcome",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/welcome",
        name: "Welcome",
        component: () => import("@/views/welcome"),
        meta: {
          title: "Welcome to Sellary",
          requiresAuth: true,
          requiresAdmin: false,
        },
      },
    ],
  },
  {
    path: "/accounts",
    component: Layout,
    children: [
      {
        path: "/accounts",
        name: "Accounts",
        component: () => import("@/views/accounts"),
        meta: {
          title: "Accounts",
          requiresAuth: true,
          requiresAdmin: false,
          icon: "mdi-account-box",
          color: "teal",
          rightDrawerForm: "AccountForm",
        },
      },
    ],
  },
  {
    path: "/automation",
    alias: "/",
    component: Layout,
    children: [
      {
        path: "/automation",
        name: "Automation",
        component: () => import("@/views/automation"),
        meta: {
          title: "Automation",
          requiresAuth: true,
          requiresAdmin: false,
          icon: "mdi-robot",
          color: "green",
          rightDrawerForm: "AutomationForm",
        },
      },
    ],
  },
  {
    path: "/listings",
    component: Layout,
    children: [
      {
        path: "/listings",
        name: "Listings",
        component: () => import("@/views/listings"),
        meta: {
          title: "Listings",
          requiresAuth: true,
          requiresAdmin: true,
          icon: "mdi-file-document",
          color: "blue",
          rightDrawerForm: "ListingForm",
        },
      },
    ],
  },
  {
    path: "/consignment/dropoff",
    component: Layout,
    children: [
      {
        path: "/consignment/dropoff",
        component: () => import("@/views/consignment/dropoff"),
        meta: {
          title: "Consigner Dropoff",
          requiresAuth: true,
          requiresAdmin: true,
          icon: "mdi-basket-plus",
          color: "purple",
        },
      },
      {
        path: "/consignment/batch",
        component: () => import("@/views/consignment/batch"),
        meta: {
          title: "Batch Consign",
          requiresAuth: true,
          requiresAdmin: true,
          icon: "mdi-hanger",
          color: "purple",
        },
      },
    ],
  },
  {
    path: "/kiosk/contract",
    component: () => import("@/views/kiosk/contract"),
    hidden: true,
    meta: {
      title: "Consigner Contract",
      requiresAuth: true,
      requiresAdmin: true,
      icon: "mdi-basket-document",
      color: "black",
    },
  },

  {
    path: "/inventory",
    component: Layout,
    children: [
      {
        path: "/inventory",
        name: "Inventory",
        component: () => import("@/views/inventoryv2"),
        meta: {
          title: "Inventory",
          requiresAuth: true,
          requiresAdmin: true,
          icon: "mdi-hanger",
          color: "orange",
          rightDrawerForm: "InventoryForm",
        },
      },
      {
        path: "category",
        component: () => import("@/views/inventoryCategory"),
        meta: {
          title: "Inventory Category",
          requiresAuth: true,
          requiresAdmin: true,
          icon: "mdi-file-document",
          color: "orange",
        },
      },
    ],
  },
  {
    path: "/about",
    component: () => import("@/views/About"),
    hidden: true,
  },
  {
    path: "*",
    name: "Not Found",
    component: NotFound,
    hidden: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve(async (to, from, next) => {
  logger.info("from-to:", from, to);

  let page = null;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let user = await getUser();

    page = !user ? { path: "/login" } : null;

    // If we are not logged in we need to move to login.
    if (page != null) return next({ path: "/login" });

    // Check if accessing admin area
    if (!AmplifyStore.getters.isAdmin && to.meta.requiresAdmin) {
      logger.info("Requires admin, but is not admin.");
      page = { path: "/automation" };
    }

    // Check if brand new user
    if (!AmplifyStore.getters.hasAccounts && to.name != "Welcome") {
      logger.warn("Redirect to welcome");
      page = { path: "/welcome" };
    }
  }

  AmplifyStore.state.nav = to.meta;

  return page == null ? next() : next(page);
});

Vue.use(AmplifyModules);
Vue.use(VueRouter);
export default router;
