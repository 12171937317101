import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import VueTour from "vue-tour";

require("vue-tour/dist/vue-tour.css");
Vue.use(VueTour);

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: "G-GECVWHB5TJ" },
});

import { Amplify, Hub, Logger } from "aws-amplify";
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";
applyPolyfills().then(() => {
  defineCustomElements(window);
});
Vue.config.ignoredElements = [/amplify-\w*/];

const ignoreWarnMessage =
  "The .native modifier for v-on is only valid on components but it was used on <div>.";
Vue.config.warnHandler = function (msg, vm, trace) {
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }

  return trace;
};

import Croppa from "vue-croppa";
Vue.use(Croppa);

Vue.config.productionTip = false;

const logger = new Logger("My-Logger");
const listener = (data) => {
  logger.info(data.payload.event);
  logger.info(data);

  switch (data.payload.event) {
    case "signIn":
      logger.info("user signed in");
      break;
    case "signUp":
      logger.info("user signed up");
      break;
    case "signOut":
      logger.info("user signed out");
      break;
    case "signIn_failure":
      logger.error("user sign in failed");
      break;
    case "tokenRefresh":
      logger.info("token refresh succeeded");
      break;
    case "tokenRefresh_failure":
      logger.error("token refresh failed");
      break;
    case "configured":
      logger.info("the Auth module is configured");
  }
};

Hub.listen("auth", listener);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
