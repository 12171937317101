import EventBus from "@/eventbus";
import store from "@/store";

export const LayoutMixin = {
  computed: {
    isNavExpanded() {
      return this.$store.state.isNavExpanded;
    },
    isDrawExpanded() {
      return this.$store.state.isRightDrawerOpen;
    },
    getDrawerContent() {
      return this.$store.state.nav.rightDrawerForm;
    },
    getNavState() {
      return this.$store.state.nav;
    },
    getFormData() {
      return this.$store.state.rightDrawerData;
    },
  },
  methods: {
    unsub(event, func) {
      EventBus.$off(event, func);
    },
    sub(event, func) {
      EventBus.$on(event, func);
    },
    pub(event, data) {
      EventBus.$emit(event, data);
    },
    handleNavToggle() {
      this.$store.commit("TOGGLE_NAV");
    },
    toggleRightDrawer() {
      this.$store.commit("TOGGLE_DRAWER");
    },
    sendIdentify() {
      if (window.HubSpotConversations) {
        onConversationsAPIReady();
      } else {
        window.hsConversationsOnReady = [onConversationsAPIReady];
      }
    },
  },
};

function onConversationsAPIReady() {
  var _hsq = (window._hsq = window._hsq || []);
  _hsq.push([
    "identify",
    {
      email: store.state.user.email,
    },
  ]);
  _hsq.push(["trackPageView"]);
  setTimeout(() => {
    window.HubSpotConversations.widget.refresh();
  }, 1000);
}
