<template>
  <v-row>
    <v-col>
      <center>
        <v-img
          src="https://sellary.app/wp-content/uploads/2021/09/404_cat-768x768.png"
          height="400"
          width="400"
        />
        <h2>Sorry the requested page was not found.</h2>
        <br />
        <v-btn depressed color="primary" to="/">Home</v-btn>
      </center>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
center {
  margin: 15vw;
}

h1 {
  color: var(--border);
  font-size: 2em;
}
</style>