<template>
  <v-layout>
    <v-app-bar app class="no-print" style="z-index: 101">
      <v-app-bar-nav-icon @click.native="dialog = true"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-img
        src="@/assets/logo.png"
        height="48"
        width="200"
        contain
        class="mt-2"
      />
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main style="width:100%">
      <v-container fluid class="pa-0 ma-0">
        <router-view />
      </v-container>
    </v-main>
    <v-sheet class="flex no-print">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-toolbar-side-icon></v-toolbar-side-icon>
        <v-card dark>
          <v-toolbar color="secondary" dark>
            <v-spacer></v-spacer>
            <v-toolbar-title> Site Navigation</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-list>
            <v-list-item
              v-for="(item, index) in navElements"
              :key="index"
              :to="item.path"
              @click.native="dialog = false"
            >
              <v-list-item-icon>
                <v-icon :color="item.children[0].meta.color">{{
                  item.children[0].meta.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.children[0].meta.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="logout"> Logout </v-list-item>
          </v-list>
        </v-card>
      </v-dialog>
    </v-sheet>
  </v-layout>
</template>
<script>
import { LayoutMixin } from "@/layout/mixins";
import { Auth } from "aws-amplify";

export default {
  mixins: [LayoutMixin],
  name: "Dashboard",
  components: {
    //Sidebar: () => import("@/layout/sidebar"),
  },
  props: {
    source: String,
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    navElements() {
      return this.$router.options.routes.filter(
        (item) =>
          item.hidden != true &&
          (item.children[0].meta.requiresAdmin == false ||
            this.$store.getters.isAdmin)
      );
    },
  },
  mounted() {},
  created() {},
  methods: {
    getUser() {
      let email = "Not Logged In";
      if (this.$store.state.user != null) email = this.$store.state.user.email;

      return email;
    },
    async logout() {
      this.$store.commit("RESET");
      try {
        await Auth.signOut();
      } catch (error) {
        console.log("error");
      }
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
