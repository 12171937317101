/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://hsfjdb8ha7.execute-api.us-east-2.amazonaws.com/beta",
            "region": "us-east-2"
        },
        {
            "name": "WoocommerceInterface",
            "endpoint": "https://kekzq44ce0.execute-api.us-east-2.amazonaws.com/beta",
            "region": "us-east-2"
        },
        {
            "name": "consignmentAPIs",
            "endpoint": "https://mu3a9v4tfk.execute-api.us-east-2.amazonaws.com/beta",
            "region": "us-east-2"
        },
        {
            "name": "ebayConnectApi",
            "endpoint": "https://t4k5sv6psj.execute-api.us-east-2.amazonaws.com/beta",
            "region": "us-east-2"
        },
        {
            "name": "inventoryAPI",
            "endpoint": "https://20kez3d502.execute-api.us-east-2.amazonaws.com/beta",
            "region": "us-east-2"
        },
        {
            "name": "oai",
            "endpoint": "https://fsgyfozcp4.execute-api.us-east-2.amazonaws.com/beta",
            "region": "us-east-2"
        },
        {
            "name": "resaleworld",
            "endpoint": "https://cnkf21mwpe.execute-api.us-east-2.amazonaws.com/beta",
            "region": "us-east-2"
        },
        {
            "name": "sellaryMsgBroadcasterApi",
            "endpoint": "https://c8ipuw9io5.execute-api.us-east-2.amazonaws.com/beta",
            "region": "us-east-2"
        },
        {
            "name": "signupAPI",
            "endpoint": "https://ogh244jvk1.execute-api.us-east-2.amazonaws.com/beta",
            "region": "us-east-2"
        },
        {
            "name": "webhooks",
            "endpoint": "https://pjholuqdye.execute-api.us-east-2.amazonaws.com/beta",
            "region": "us-east-2"
        },
        {
            "name": "wooWebHook",
            "endpoint": "https://uyp3fg3t6c.execute-api.us-east-2.amazonaws.com/beta",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://izg74wpbhbhnzntfa5tp6o2riy.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:c89d19f6-60de-4b56-8a05-7ab5f931277b",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_HcuklFV1a",
    "aws_user_pools_web_client_id": "1a8kgto0o3ccancjr4tlk74qbq",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "omni-posh-media-uploadbeta-beta",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
