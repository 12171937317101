import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import Amplify from "aws-amplify";

Vue.use(Vuex);

var logger = new Amplify.Logger("Store");

function _initialState() {
  return {
    user: null,
    isNavExpanded: true,
    isRightDrawerOpen: false,
    nav: { title: "", icon: "", path: "", color: "", rightDrawerForm: "" },
    rightDrawerData: {},
    tasks: [],
    accounts: [],
  };
}

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: _initialState(),
  mutations: {
    SET_ACCOUNTS(state, accounts) {
      state.accounts = accounts;
    },
    SET_USER(state, user) {
      if (null != user) {
        let groups = user.signInUserSession.idToken.payload["cognito:groups"];

        state.user = {
          email: user.attributes.email,
          username: user.attributes.sub,
          isAdmin:
            groups !== undefined &&
            user.signInUserSession.idToken.payload["cognito:groups"].includes(
              "Admins"
            )
              ? true
              : false,
        };
      } else {
        user = null;
      }
    },
    RESET: (state) => {
      const s = _initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    TOGGLE_NAV: (state) => {
      state.isNavExpanded = !state.isNavExpanded;
    },
    TOGGLE_DRAWER: (state) => {
      state.isRightDrawerOpen = !state.isRightDrawerOpen;
    },
    SET_DRAWER_DATA: (state, data) => {
      state.rightDrawerData = data;
    },
  },
  getters: {
    hasAccounts: (state) => {
      logger.info("hasAccountsGetter()", state.accounts.length);
      return state.accounts.length > 0 ? true : false;
    },
    isAdmin: (state) => {
      console.log(state.user);
      return state.user !== null && (state.user.username == "3ebbdff3-e102-4579-a50f-10b0c031e83c" || state.user.username == "b2e92aa1-1be4-47ee-9e7b-541329163d5e")
      //return state.user !== null && state.user.isAdmin == true ? true : false;
    },
  },
  actions: {},
  modules: {},
});
